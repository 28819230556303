<template>
  <div>
    <Header /> <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="hotairtools()">
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../assets/images/subcategorie/soldering_rework/convection_rework/hot_air.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">Hot Air Tools</h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="preheaters()">
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 60%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../assets/images/subcategorie/soldering_rework/convection_rework/PREHEATERS.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">Preheaters</h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../components/Header.vue";

import Footer from "../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
  methods: {
      hotairtools(){
          window.location.href = "https://store.metcal.com/en-us/shop/convection-rework/hot-air-tools/";
      
    },
     preheaters(){
        window.location.href = "https://store.metcal.com/en-us/shop/convection-rework/preheaters/";

    },
  },
};
</script>